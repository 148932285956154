import React, { FunctionComponent, ReactElement } from 'react';
import * as serviceWorker from './common/serviceWorker';
import { createBrowserHistory } from 'history';
import 'normalize.css';
import './reset.local.scss';
import './index.scss';
import AppContainer from './AppContainer';
import { Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

const history = createBrowserHistory();

const Index: FunctionComponent = (): ReactElement => {
    return (
        <Router history={history}>
            <AppContainer />
        </Router>
    );
};

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<Index />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
