import './common/gearboxComponents';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { HOME_PAGE, REGISTRATION_PATH } from './common/routes';
import { BasicAppLayout } from '@mo/app-layout';
import AppContext from './common/appContext';
import styles from './app.module.scss';
import { blankConfig } from './domain/Config';
import { useConfig } from './common/config/useConfigHook';
import RegistrationRouter from './pages/registration/RegistrationRouter';
import { BASE_URL } from './common/gateway';
import VerificationRouter from './pages/verification/VerificationRouter';

const AppContainer: FunctionComponent = (): ReactElement | null => {
    const [config, setConfig] = useState(blankConfig);

    const value = {
        config,
        setConfig,
    };

    const { loading, config: configResponse } = useConfig();

    useEffect(() => {
        setConfig(configResponse);
    }, [configResponse]);

    if (loading) {
        return null;
    }

    return (
        <AppContext.Provider value={value}>
            <div className={styles.app}>
                <BasicAppLayout baseUrl={BASE_URL} currentPath={'/'}>
                    <div className={styles.pageContent}>
                        <BrowserRouter>
                            <Switch>
                                <Route path={REGISTRATION_PATH} component={RegistrationRouter} />
                                <Route path={HOME_PAGE} component={VerificationRouter} />
                            </Switch>
                        </BrowserRouter>
                    </div>
                </BasicAppLayout>
            </div>
        </AppContext.Provider>
    );
};

export default AppContainer;
